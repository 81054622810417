@import "./variables.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif, "TT Squares Regular", "TT Squares Bold";
}

html {
  /* changes a default 16px font size to 10px */
  font-size: 70%;
  font-family: "Open Sans";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0;
}

h1 {
  font-size: 2rem;
}

font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

font-face {
  font-family: "Cocogoose";
  font-style: normal;
  font-weight: normal;
  src: local("Cocogoose"),
    url("./fonts/Cocogoose/Cocogoose.otf") format("opentype");
}

font-face {
  font-family: "Neuropol";
  font-style: normal;
  font-weight: normal;
  src: local("Neuropol"),
    url("./fonts/Neuropol/Neuropol-X-regular.ttf") format("truetype");
}

font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: local("Neuropol"),
    url("./fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Consolas";
  font-style: normal;
  font-weight: normal;
  src: local("Consolas"),
    url("./fonts/Consola/consola.ttf") format("truetype");
}

font-face {
  font-family: "Soho Gothic";
  font-style: normal;
  font-weight: normal;
  src: local("Neuropol"),
    url("./fonts/SohoGothic/SohoGothicPro-Regular.otf") format("OpenType");
}

@font-face {
  font-family: "TT Squares Bold";
  font-style: normal;
  font-weight: bold;
  src: local("TTSquaresBold"),
    url("./fonts/TTSquares/TTSquares-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "TT Squares Regular";
  font-style: normal;
  font-weight: normal;
  src: local("TTSquaresRegular"),
    url("./fonts/TTSquares/TTSquares-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","TT Squares Regular", "TT Squares Bold",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  "TT Squares Regular", "TT Squares Bold",source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  "TT Squares Regular", "TT Squares Bold",
    monospace;
}
