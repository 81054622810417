.css-1pssh7i-MuiStack-root {
    padding: 0 !important;
  }
  
  .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .image-container img {
      max-width: 45%;
      height: auto;
      border-radius: 10px;
      /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); */
    }
    
    .instructions-container {
      text-align: center;
    }
    .text {
      text-align: justify;
      font-size: 16px;
      padding: 8px;
    }
    .span {
      font-weight: bold;
    }
    .h1 {
      color: #00123A;
    }
    .link {
      color: #00123A;
      font-weight: bold;
    }
    .instructions-container a {
      font-size: 16px;
      color: var(--main-blue);
      padding: 8px;
      display: inline-block;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 
      overflow-y: scroll;
      height: 100vh; 
      z-index: 2;
      pointer-events: auto;
    }
    
    .text {
      position: absolute;
      bottom: 0;
      width: 100%;
      color: #fff; 
      text-align: left;
      font-family: 'TT Squares Regular';
      padding: 10px;
      font-size: 12px;
      border-radius: 0 0 10px 10px; 
    }
        
    .color-aqui {
      color: var(--sec-orange);
    }
    .instructions-container a:hover {
      text-decoration: underline;
    }
    
    .buttons-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
    
    button {
      border-radius: 20px;
      padding: 8px 16px;
    }
    